import React from "react";
import Row from "../Row/Row";
import { requests } from "../../Axios/requests";
import Banner from "../Banner/Banner";
import Nav from "../Nav/Nav";
import { useParams } from "react-router-dom";
import "./Home.css";
// import { useStateValue } from "../../ContextApi/stateProvider";
// import { truncate } from "../../Utils/truncate";

function Home() {
  const { param } = useParams();
  // eslint-disable-next-line
  // const [{ user }] = useStateValue();

  return (
    <div className="home">
      <Nav />
      <Banner />
      {param === undefined && (
        <Row
          title="Trending JAV Now"
          isLargeRow
          fetchUrl={requests.fetchTrending}
        />
      )}

      {(param === undefined || param === "jav") && (
        <>
          {param === "jav" && (
            <Row
              title="JAV Trending Now"
              isLargeRow
              fetchUrl={requests.fetchTrending5_10}
            />
          )}
          <Row title="Popular JAV on MovieAV" fetchUrl={requests.fetchTrending10_100} />
          <Row
            title="MovieAV Originals"
            fetchUrl={requests.fetchTrending101_200}
          />
          <Row title="New JAV Releases" fetchUrl={requests.fetchAiringTodayTV} />
        </>
      )}

      {param === undefined && (
        <Row
          title={
            "Top 20 View จัดอันดับคนดูเยอะที่สุด20อันดับ" 
          }
          fetchUrl={requests.fetchTrending}
        />
      )}

      {(param === undefined || param === "clip") && (
        <>
          <Row title="Recently Added" fetchUrl={requests.fetchClip} />
          {param === "clip" && (
            <Row
              title="Trending Clip Now คลิปหลุด ที่กำลังเป็นกระแส"
              isLargeRow
              fetchUrl={requests.fetchXclubTH}
            />
          )}
          {/*<Row title="Onlyfans Mlive สาวๆโชว์เสียวๆ" fetchUrl={requests.fetchClip11_15} />
          <Row title="HomeMade คลิปหลุด" fetchUrl={requests.fetchClip16_20} />
          <Row title="Pro หนังเย็ดกัน" fetchUrl={requests.fetchClip21_25} />
           <Row title="Shorted Yed ช็อตสั้นแต่เสียว" fetchUrl={requests.fetchRomanceMovies} />
          <Row
            title="Documentaries"
            fetchUrl={requests.fetchDocumentariesMovies}
          /> */}
        </>
      )}

      {param === "exclusive" && (
        <>
          <Row
            title="Who Is She เธอคือใคร?"
            isLargeRow
            fetchUrl={requests.fetchExclusive_001}
          />
          <Row title="New Exclusive on MovieAV" fetchUrl={requests.fetchExclusive_002} />
          <Row
            title="กำลังจะมา"
            fetchUrl={requests.fetchExclusive_003}
          />
        </>
      )}
    </div>
  );
}

export default Home;
