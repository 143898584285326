import React, { useRef } from "react";
import "./Nav.css";
import { useEffect } from "react";
import { useState } from "react";
// eslint-disable-next-line
import { ArrowDropDown, Clear, ExpandMore, Search } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
// eslint-disable-next-line
import { auth } from "../../Config/firebase";
// eslint-disable-next-line
import { actionType } from "../../ContextApi/reducer";
import { useStateValue } from "../../ContextApi/stateProvider";
import { ClickAwayListener } from "@material-ui/core";
import netflixLogo from "../../Images/netflixLogo.png";
// eslint-disable-next-line
import { truncate } from "../../Utils/truncate";
// import { LandingPage } from "../../Components/LandingPage"

function Nav() {
  const [show, handleShow] = useState(false);
  const [query, setQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  // eslint-disable-next-line
  const [showDropdown, setShowDropdown] = useState(false);
  // eslint-disable-next-line
  const [{ user }, dispatch] = useStateValue();
  const history = useHistory();
  const [expandMore, setExpandMore] = useState(false);
  // eslint-disable-next-line
  const [userAvatar, setUserAvatar] = useState(2);
  const inputField = useRef(null);

  useEffect(() => {
    //random useravatar image.
    setUserAvatar(Math.floor(Math.random() * 1) + 1);

    //change nav style on scroll more than 60px from top
    const navHandler = () => {
      if (window.scrollY > 50) {
        handleShow(true);
      } else {
        handleShow(false);
      }
    };

    window.addEventListener("scroll", navHandler);

    return () => {
      //clean up
      window.removeEventListener("scroll", navHandler);
    };
  }, []);

  // const signOut = () => {
  //   auth
  //     .signOut()
  //     .then(() => {
  //       dispatch({
  //         type: actionType.SET_USER,
  //         user: null,
  //       });
  //     })
  //     .catch((err) => {
  //       alert(err.message);
  //     });
  // };

  const searchHandler = (e) => {
    e.preventDefault();
    history.push(`/search/${query}`);
    setQuery("");
  };

  //just for indication
  // const dropClickHandler = () => {
  //   alert(
  //     "Not added this functionality.\nClick on signout option in dropdown menu to signout of Netflix."
  //   );
  // };

  return (
    <div className={`nav ${show ? "nav__black" : ""}`}>
      <div className="nav__leftSide">
        <Link to="/">
          <img className="nav__logo" src={netflixLogo} alt="AVFREEX24 logo" />
        </Link>
        <ClickAwayListener onClickAway={() => setExpandMore(false)}>
          <div className="expandMore__content">
            <ExpandMore
              onClick={() => {
                setExpandMore(!expandMore);
              }}
            />
            <div className={`nav__links ${!expandMore ? "navMobile__links" : ""}`}>
              <span
                style={
                  history.location.pathname === "/" ? { color: "white" } : {}
                }
                onClick={() => history.push("/")}
              >
                หนังAVเต็มเรื่องล่าสุด
              </span>

              <span
                style={
                  history.location.pathname === "/browse/jav"
                    ? { color: "white" }
                    : {}
                }
                onClick={() => history.push("/browse/jav")}
              >
                Jav
              </span>

              <span
                style={
                  history.location.pathname === "/browse/clip"
                    ? { color: "white" }
                    : {}
                }
                onClick={() => history.push("/browse/clip")}
              >
                คลิปหลุด
              </span>

              <span
                style={
                  history.location.pathname === "/browse/exclusive"
                    ? { color: "white" }
                    : {}
                }
                onClick={() => history.push("/browse/exclusive")}
              >
                Inside & Exclusive
              </span>
              <span
                style={
                  history.location.pathname === "/club"
                    ? { color: "red" }
                    : {}
                }
                onClick={() => history.push("/club")}
              >
                X-club
              </span>
            </div>
          </div>
        </ClickAwayListener>
      </div>
      <div className="nav__rightSide">
        {/* Search Field */}

        <form
          onSubmit={searchHandler}
          className={showSearch ? "showSearch" : ""}
        >
          <input
            ref={inputField}
            //hide search field if not in focus and clear the text entered.
            onBlur={() => {
              setQuery("");
              setShowSearch(false);
            }}
            required
            placeholder="ค้นหาจากชื่อ หรือ รหัส"
            type="search"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <Search
            style={
              //icon on search field
              showSearch ? { zIndex: "1" } : { display: "none" }
            }
            className="searchIcon"
          />
          {query && <Clear className="searchCross" />}
        </form>
        <Search
          //search icon get hidden when clicked
          style={
            !showSearch
              ? { animation: "zoomAnimation 0.8s" }
              : { visibility: "hidden" }
          }
          onClick={() => {
            setShowSearch(true);
            inputField.current.focus();
          }}
        />
        <img
          className="nav__avatar"
          src={ `/images/users/1.jpg`}
          alt="สนับสนุนโดย AVFREEX24"
        />
        <span className="nav__userName">
         สนับสนุนโดย AVFREEX24
        </span>
        <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
          <span className="dropDown__arrow">
            {/* <ArrowDropDown
              onClick={() => {
                setShowDropdown(!showDropdown);
              }}
            />
            <div
              className="dropdown__list"
              style={showDropdown ? {} : { display: "none" }}
            >
              <ul>
                <li>
                  <img
                    className="nav__avatar"
                    src={ `/images/users/1.jpg`}
                    alt="สนับสนุนโดย AVFREEX24"
                  />
                  <span>
                      สนับสนุนโดย AVFREEX24
                  </span>
                </li>
                <li onClick={dropClickHandler}>
                  <img src="/images/users/4.png" alt="" />
                  User 2
                </li>
                <li onClick={dropClickHandler}>
                  <img src="/images/users/5.png" alt="" />
                  User 3
                </li>
                <li onClick={dropClickHandler}>Manage Profiles</li>
                <li className="dropdown__options" onClick={dropClickHandler}>
                  Account
                </li>
                <li className="dropdown__options" onClick={dropClickHandler}>
                  Help Center
                </li>
                <li
                  className="dropdown__options"
                  onClick={() => {
                    signOut();
                  }}
                >
                  Sign out of Netflix
                </li>
              </ul>
            </div> */}
          </span>
        </ClickAwayListener>
      </div>
    </div>
  );
}

export default Nav;
