export const API_KEY = process.env.REACT_APP_API_KEY;
export const BASE_URL = "https://hideyed.avfreex24.com";

function between(min, max) {  
  return Math.floor(
    Math.random() * (max - min) + min)
}
//https://movie-ufax-default-rtdb.asia-southeast1.firebasedatabase.app/moviesite.json
export const requests = {
  //AV
  fetchTrending:`/mvav?host=1&page=${between(1, 5)}`,
  fetchTrending5_10: `/mvav?host=1&page=${between(6, 10)}`,
  fetchTrending10_100: `/mvav?host=1&page=${between(10, 100)}`,
  fetchTrending101_200: `/mvav?host=1&page=${between(101, 200)}`,
  fetchTrending201_300: `/mvav?host=1&page=${between(201, 300)}`,
  fetchTrending301_1000: `/mvav?host=1&page=${between(301, 1000)}`,

  //CLIP
  fetchClip:`/cliplist`,
  fetchClip6_10: `/clip2vip?page=${between(6, 10)}`,
  fetchClip11_15: `/clip2vip?page=${between(11, 15)}`,
  fetchClip16_20: `/clip2vip?page=${between(16, 20)}`,
  fetchClip21_25: `/clip2vip?page=${between(21, 25)}`,
  // fetchClipID: `/clip/`,

  fetchExclusive_001: `/mvav?host=${between(0, 15)}&page=${between(1, 1000)}`,
  fetchExclusive_002: `/mvav?host=${between(0, 15)}&page=${between(1, 1000)}`,
  fetchExclusive_003: `/mvav?host=${between(0, 15)}&page=${between(1, 1000)}`,
  // fetchTopRatedMovies: `/mvav?host=0&page=1`,
  // fetchPopularMovies: `/mvav?host=0&page=1`,
  // fetchActionMovies: `/mvav?host=0&page=1`,
  // fetchComedyMovies: `/mvav?host=0&page=1`,
  // fetchHorrorMovies: `/mvav?host=0&page=1`,
  // fetchRomanceMovies: `/mvav?host=0&page=1`,
  // fetchDocumentariesMovies: `/mvav?host=0&page=1`,

  //x-club

  fetchXclubTH: `./clipthai.json`, 
  // fetchXclubCH: `https://movie-ufax-default-rtdb.asia-southeast1.firebasedatabase.app/movieav/ch.json?print=pretty`,
  // fetchXclubJP: `https://movie-ufax-default-rtdb.asia-southeast1.firebasedatabase.app/movieav/jp.json?print=pretty`,
  // fetchXclubKO: `https://movie-ufax-default-rtdb.asia-southeast1.firebasedatabase.app/movieav/ko.json?print=pretty`,
  //Search

  fetchSearchResult: `/mvav?host=1&page=2`,
  fetchSearchResultMovies: `/mvav?host=2&page=2`,
  fetchSearchResultSeries: `/mvav?host=3&page=2`,
};
