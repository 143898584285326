import React, { useEffect, useRef, useState } from "react";
import "./Details.css";
import axios from "../../Axios/axios";
import { Add, Close, PlayArrow } from "@material-ui/icons";
// import YouTube from "react-youtube";
// import Loading from "../Loading/Loading";
// import { API_KEY, BASE_URL } from "../../Axios/requests";
import { truncate } from "../../Utils/truncate";
import {
  Player,
  Hls,
  DefaultUi,
  Scrim,
  Controls,
  ControlSpacer,
  MuteControl,
  PlaybackControl,
  TimeProgress,
} from '@vime/react';
import '@vime/core/themes/default.css';

function Details({ itemDetails, handleClose }) {
  const [trailerUrl, setTrailerUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const trailerBox = useRef(null);

  const hlsConfig = {
    // ...
  };

  useEffect(() => {
    //setting trailerurl and loading because on changing itemdetails trailerurl
    //does not change and show trailer of previously opened item .
    setTrailerUrl("");
    setLoading(false);
  }, [itemDetails]);

  const handleClick = () => {
    if (trailerUrl) {
      // const mvps = localStorage.getItem('AVfreex24VVIDURL');
      setTrailerUrl("");
      setLoading(false);
    } else {
      //fetching trailer for item from TMDb API
      async function fetchTrailer() {
        setLoading(true);
        const mvps = localStorage.getItem('AVfreex24VVIDURL');
        if (mvps === "") {
          axios.get(
                `/clip/${itemDetails?.id}`
              )
                .then((responseTvTrailer) => {
                  if (
                    responseTvTrailer.data?.results[0]?.length === 0 ||
                    !responseTvTrailer.data?.results[0].video_link
                  ) {
                    setLoading(false);
                   
                  } else {
                    setTrailerUrl(responseTvTrailer.data.results[0].video_link);
                    trailerBox.current.focus();
                  }
                })
                .catch(() => {
                  // No Trailer is available for item in TMDb
                  setLoading(false);
                  alert(
                    "Trailer not available.\nTry:\n1.Checking the connection.\n2.Playing different TV, Movie trailer."
                  );
            });
        } else {
          setTrailerUrl("");
          trailerBox.current.focus();
        }
        setTrailerUrl(mvps);
        trailerBox.current.focus();
      }
      // async function fetchClipp() {
      //   axios.get(
      //     `/clip/${itemDetails?.id}`
      //   )
      //     .then((responseTvTrailer) => {
      //       if (
      //         responseTvTrailer.data?.results?.length === 0 ||
      //         !responseTvTrailer.data?.results[0]?.video_link
      //       ) {
      //         setLoading(false);
             
      //       } else {
      //         setTrailerUrl(responseTvTrailer.data.results[0].video_link);
      //         trailerBox.current.focus();
      //       }
      //     })
      //     .catch(() => {
      //       // No Trailer is available for item in TMDb
      //       setLoading(false);
      //       alert(
      //         "Trailer not available.\nTry:\n1.Checking the connection.\n2.Playing different TV, Movie trailer."
      //       );
      //});
     // }
      fetchTrailer();
     // fetchClipp();
    }
  };

  return (
    <div className="details">
      <div className="details__box">
        <div className="details__close" onClick={handleClose}>
          <Close />
        </div>
        <div className="details__content">
          <h1 className="details__heading">
            {itemDetails?.title ||
              itemDetails?.name ||
              itemDetails?.original_title}
          </h1>
          <h5>
            <span className="details__rating">
              {itemDetails?.vote_average ? itemDetails?.vote_average * 10 : 0}
              %&nbsp;ความนิยม
            </span>
            <span>
              {itemDetails?.first_air_date?.substr(0, 4) ||
                itemDetails?.release_date?.substr(0, 4)}
            </span>
            {itemDetails?.media_type ? (
              <span>{itemDetails?.media_type}</span>
            ) : (
              ""
            )}
            <span className="details__resolution">HD</span>
            <span className="details__lan">
              {itemDetails?.original_language}
            </span>
          </h5>
          <p className="details__description">
            {truncate(itemDetails?.overview, 320)}
          </p>
          <div className="details__buttons">
            <button
              className="details__button details__playTrailer"
              onClick={handleClick}
            >
              <span className="button__text">
                {!trailerUrl ? <PlayArrow /> : <Close />}
                &nbsp;{!trailerUrl ? "Play" : "Close"}
              </span>
            </button>
            <button
              className="details__button details__buttonGhost"
              onClick={() => {
                alert(
                  "ไม่ได้เพิ่มฟังก์ชันนี้\nกดปุ่มเล่นเพื่อเล่นตัวอย่าง"
                );
              }}
            >
              <span className="button__text">
                <Add />
                &nbsp;เก็บไว้ในใจ
              </span>
            </button>
          </div>
        </div>
        <div
          className="details__img"
          style={
            itemDetails
              ? {
                backgroundImage: `linear-gradient(
              90deg,
              rgba(0, 0, 0, 1),
              rgba(0, 0, 0, 0.4)),
              url("${itemDetails?.backdrop_path
                    ? itemDetails?.backdrop_path
                    : itemDetails?.poster_path
                  }")`,
              }
              : {}
          }
        ></div>
      </div>

      {/* Trailer Container */}

      <div
        className={`trailerBox ${loading ? "details__videoContainer" : ""}`}
        tabIndex="-1"
        ref={trailerBox}
      >
        {trailerUrl && (
          <Player
          autoplay
            onReady={(e) => {
              setLoading(false);
              //e.target.playVideo();
            }}
          >
            {/* We turn off the controls that come with the default UI. */}
            <DefaultUi>
              <Scrim />
              <Hls version="latest" config={hlsConfig} poster={itemDetails.poster_path}>
                <source data-src={itemDetails.avfreex24_file || itemDetails.original_file} type={"application/x-mpegURL" || "video/mp4"} />
              </Hls>
              <Controls fullWidth pin="topLeft">
                <ControlSpacer />
                <MuteControl />
              </Controls>

              <Controls pin="center">
                <PlaybackControl hideTooltip style={{ '--vm-control-scale': 1.7 }} />
              </Controls>

              <Controls fullWidth pin="bottomLeft">
                <ControlSpacer />
                <TimeProgress />
              </Controls>
            </DefaultUi>
          </Player>
        )}
      </div>
    </div>
  );
}

export default Details;
